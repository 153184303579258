export default defineNuxtRouteMiddleware(async (_to, _from) => {
	// skip middleware on server
	if (import.meta.server) return;
	// skip middleware on client side entirely
	// if (import.meta.client) return;

	// or only skip middleware on initial client load
	// const nuxtApp = useNuxtApp();
	// if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return;
	const utilsState = useUtilsDataStore();

	const { countries, states } = storeToRefs(utilsState);

	if (countries.value.length === 0) {
		await utilsState.fetchCountryList();
	}

	if (states.value.length === 0) {
		await utilsState.fetchStateList();
	}
});
